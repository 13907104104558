import angular from "angular";
import idleTimedoutDialogTemplate from './areas/modals/idle-timedout.html';
import idleWarningDialogTemplate from './areas/modals/idle-warning.html';
import _ from 'underscore';

(function () {
    "use strict";

    angular.module("cardspotWeb")
    .config(['KeepaliveProvider', 'IdleProvider', "CARDSPOT_SERVICE_FOLDER", function (KeepaliveProvider, IdleProvider, CARDSPOT_SERVICE_FOLDER) {
        IdleProvider.idle(13*60); //13 minutes of inactivity until idle starts
        IdleProvider.timeout(60); //60 seconds to respond to pop up.
        KeepaliveProvider.interval(10*60); //10 minute interval for heartbeat
        KeepaliveProvider.http(CARDSPOT_SERVICE_FOLDER + "/keepalive"); //Heartbeat api call.
    }])
		.run(["$rootScope", "$q", "$translate", "$location", "$confirmModalDefaults", "NAV_URLS", "DATE_FORMATS", "DEFAULT_DATE_FORMAT", "DEFAULT_DATE_TIME_FORMAT", "appConfig", "routeBoss", "authService", "navigate", "notify", "LANGUAGES", "CLAIMS", "DEFAULT_VALUES", "EVENT_NAMES", "cardService", "preActivationService", "sundryService", "transactionService", "customerService", "lookupValues", "Idle", "$uibModal", "Keepalive", "fulfillmentService", "FOOTER_URLS", "limitService", "orderService",
            function ($rootScope, $q, $translate, $location, $confirmModalDefaults, NAV_URLS, DATE_FORMATS, DEFAULT_DATE_FORMAT, DEFAULT_DATE_TIME_FORMAT, appConfig, routeBoss, authService, navigate, notify, LANGUAGES, CLAIMS, DEFAULTS_VALUES, EVENT_NAMES, cardService, preActivationService, sundryService, transactionService, customerService, lookupValues, Idle, $uibModal, Keepalive, fulfillmentService, FOOTER_URLS, limitService, orderService) {

				$rootScope.NAV_URLS = NAV_URLS;
	            $rootScope.FOOTER_URLS = FOOTER_URLS;
                $rootScope.CLAIMS = CLAIMS;
                $rootScope.DEFAULT_VALUES = DEFAULTS_VALUES;
                $rootScope.EVENT_NAMES = EVENT_NAMES;
                $rootScope.hasSelectedMerchantGroup = appConfig.hasSelectedMerchantGroup;
                $rootScope.selectedMerchantGroup = appConfig.selectedMerchantGroup;
                $rootScope.getLocation = appConfig.getSelectedLocation;
                $rootScope.hasSundryTypes = appConfig.hasSundryTypes;
                $rootScope.isAuthenticated = authService.isAuthenticated;
                $rootScope.isReauthenticating = authService.isReauthenticating;
                $rootScope.languages = LANGUAGES;
                $rootScope.useLanguage = function (language) {
                    $translate.use(language.key);
                };
                setCurrencyFormat();

                $rootScope.$on('$translateChangeSuccess', function (event, args) {
                    $rootScope.$broadcast(EVENT_NAMES.ON_CULTURE_CHANGED);
                    if (args.language) {
                        setCurrencyFormat();
                    }
                });
                $rootScope.logout = function () {
                    authService.logout(function () {
                        authService.removeReauthenticationKey();
                        authService.removeConfirmSignage();
                        cardService.clearAll();
                        preActivationService.reset();
                        sundryService.reset();
                        appConfig.resetMerchantGroups();
                        customerService.reset();
                        transactionService.reset();
                        lookupValues.reset();
                        fulfillmentService.reset();
                        limitService.reset();
                        navigate.toLogin();
                        orderService.reset();
                    });
                };

                $rootScope.$on("$routeChangeStart", function (event, nextRoute) {
                    if (nextRoute.$$route) {
                        routeBoss.manage({
                            route: nextRoute.$$route,
                            routeChangeEvent: event,
                            nextRouteUrl: nextRoute.$$route.originalPath
                        });
                    }
                });

                $rootScope.$on("$routeChangeSuccess", function (event, nextRoute) {
                    if (nextRoute.$$route) {
                        notify.dismissErrorAlert();
                    }
                });

                $rootScope.$on(EVENT_NAMES.UNAUTHORIZED, function () {
                    reauthenticateUser();
                });

                $rootScope.$on(EVENT_NAMES.PASSWORD_CHANGE_REQUIRED, function () {
                    authService.setPasswordChangeRequiredKey();
                    navigate.toLogin();
                });

                $rootScope.currentLanguage = function () {
                    return $translate.currentLanguage();
                };

                $rootScope.currentDateFormat = function (showTime) {
                    var timeFormat = " hh:mm:ss a"
                    var currentLang = $translate.currentLanguage(),
                        formatName = currentLang ? currentLang.dateFormat : DEFAULT_DATE_FORMAT,
                        format = _.find(DATE_FORMATS, function (dateFormat) {
                            return dateFormat.name === formatName;
                        });
                    if (format && showTime) {
                        return format.formatString.toUpperCase() + timeFormat
                    }
                    else if (format) {
                        return format.formatString;
                    }
                    throw "The date format string for {formatName} could not be found.".supplant({ formatName: formatName });
                };

                $rootScope.currentDateTimeFormat = function () {
                    let dateFormatString = $rootScope.currentDateFormat(),
                        timeFormatString = DEFAULT_DATE_TIME_FORMAT;

                    return dateFormatString + " " + timeFormatString;
                };

                function setCurrencySeparators() {
                    $rootScope.currencySeparators = {
                        GROUP_SEP: $translate.currentLanguage().currencyFormat.groupSeparator,
                        DECIMAL_SEP: $translate.currentLanguage().currencyFormat.decimalSeparator
                    };
                }

                function setIsTrailingSymbol() {
                    $rootScope.isTrailingSymbol = $translate.currentLanguage().currencyFormat.isTrailingSymbol;
                }

                function setFractionSize() {
                    $rootScope.fractionSize = $translate.currentLanguage().currencyFormat.fractionSize;
                }

                function setCurrencyFormat() {
                    setCurrencySeparators();
                    setIsTrailingSymbol();
                    setFractionSize();
                }

                function reauthenticateUser() {
                    // This currently isn't used, but is kept here to see if business will want to reimplement
                    var currentUserName = authService.currentUsername();
                    if (!currentUserName) {
                        return;
                    }

                    lookupValues.reset();
                    authService.logout(function () {
                        authService.setReauthenticationKey();
                    });
                    navigate.toReauthenticate($location.path(), currentUserName);
                }

                function isLoginPage() {
                    return $location.path().startsWith(NAV_URLS.LOGIN);
                }

                $rootScope.isLoginPage = function () {
                    return isLoginPage();
                };

                function isConfirmInventoryPage() {
                    return $location.path().startsWith(NAV_URLS.CONFIRM_INVENTORY)
                }

                $rootScope.isConfirmInventoryPage = function () {
                    return isConfirmInventoryPage();
                }

                function isRemoveInventoryPage() {
                    return $location.path().startsWith(NAV_URLS.REMOVE_INVENTORY);
                }

                $rootScope.isRemoveInventoryPage = function () {
                    return isRemoveInventoryPage();
                }

                function isDiagnosticPage() {
                    return $location.path().startsWith(NAV_URLS.DIAGNOSTIC);
                }

                $rootScope.isDiagnosticPage = function() {
                    return isDiagnosticPage();
                }

                $rootScope.showMenu = function() {
                    return !isLoginPage();
                }

                function closeModals() {
                    if ($rootScope.warning) {
                        $rootScope.warning.close();
                        $rootScope.warning = null;
                    }

                    if ($rootScope.timedout) {
                        $rootScope.timedout.close();
                        $rootScope.timedout = null;
                    }
                }

                $rootScope.integratedPaymentsEnabled = function(){
                    return (typeof CefSharp !== "undefined");
                }

                $rootScope.$on('IdleStart', function () {
                    closeModals();

                    $rootScope.warning = $uibModal.open({
                        template: idleWarningDialogTemplate,
                        windowClass: "modal-danger",
                        size:"sm"
                    });
                });

                $rootScope.$on('IdleEnd', function () {
                    closeModals();
                });

                $rootScope.$on('IdleTimeout', function () {
                    closeModals();
                    $rootScope.timedout = $uibModal.open({
                        template: idleTimedoutDialogTemplate,
                        windowClass: "modal-danger",
                        size: "sm"
                    });
                    $rootScope.logout();
                });

                if (!$rootScope.isLoginPage()) {
                    Idle.watch();
                }
                $rootScope.cssClass = function () {
                    if (isLoginPage()) {
                        return "";
                    } else {
                        return "main-offset";
                    }
                };
        }]);
}());