(function () { 
	"use strict";

	angular.module("cardspotWeb.areas.fulfillment")
		.controller("CardFrontFulfillment", CardFrontFulfillment);

	CardFrontFulfillment.$inject = ["$scope", "$translate", "notify", "fulfillmentService", "ngDialog", "authService", "CLAIMS",
	"SF_EVENTS", "receipts", "EVENT_NAMES", "cardService", "$location", "LOOKUP_TYPES", "$uibModal", "$window", "moment", "FileSaver",
	"FULFILLMENT_ORDER_TYPES", "ORDER_STATUS"];

	function CardFrontFulfillment($scope, $translate, notify, fulfillmentService, ngDialog, authService, CLAIMS,
        SF_EVENTS, receipts, EVENT_NAMES, cardService, $location, LOOKUP_TYPES, $uibModal, $window, moment, FileSaver,
		FULFILLMENT_ORDER_TYPES, ORDER_STATUS) {
		var model = this,
			fulfilled = "Fulfilled",
			shipping = "Shipping",
			funded = "Funded",
			approved = "KybApproved",
			cancelled = "Cancelled",
			batchActivation = "Batch Activation",
            hvt_threshold = 50000;

		model.card = {
			printText: undefined,
			firstPrintText: null,
			firstPrimaryAccountNumber: null,
			lastPrintText: null,
			lastPrimaryAccountNumber: null,
			groupCardCount: null
		};

		model.lockTransactionOnActivation = false;

		model.historySearch = {
			filterText: null,
			orderBeginDate: null,
			orderEndDate: null
		};

		model.queueSearch = {
			filterText: null,
			orderBeginDate: null,
			orderEndDate: null
		};

		model.sort = sort;
		model.sortKey = "importanceOrder";
		model.reverse = false;
		model.sortCardholder = sortCardholder;
		model.cardholderSortKey = "importanceOrder";
		model.cardholderReverse = false;
        model.canFulfillCardFrontOrders = authService.hasClaim(CLAIMS.FULFILL_CARDFRONT_ORDERS);
        model.canFulfillCorporateOrders = authService.hasClaim(CLAIMS.FULFILL_CORPORATE_ORDERS);
		model.getOrderStatusDisplay = getOrderStatusDisplay;
		model.getStatusModifiedDate = getStatusModifiedDate;
		model.isInProgress = isInProgress;
		model.beginFulFillment = beginFulFillment;
		model.overRideFulfillmentAssociation = overRideFulfillmentAssociation;
		model.cancelOverride = cancelOverride;
		model.preActivateCardByPrintText = preActivateCardByPrintText;
		model.preActivateGroupCardByFirstPrintText = preActivateGroupCardByFirstPrintText;
		model.preActivateGroupCardByLastPrintText = preActivateGroupCardByLastPrintText;
		model.isValidForActivation = isValidForActivation;
		model.activateOrder = activateOrder;
		model.getShippingAddressForPrint = getShippingAddressForPrint;
		model.cancelActivation = cancelActivation;
		model.cancelOrder = cancelOrder;
		model.activationInProcess = false;
		model.getFulfillmentQueue = getFulfillmentQueue;
		model.getFulfillmentHistory = getFulfillmentHistory;
		model.historyPageChanged = historyPageChanged;
		model.historySortChanged = historySortChanged;
		model.historyPageSizeChanged = historyPageSizeChanged;
		model.doLookupFulfillmentOrder = doLookupFulfillmentOrder;
		model.trackingInformationChanged = false;
		model.changeTrackingInformation = changeTrackingInformation;
		model.canEditTrackingInformation = false;
		model.updateOrder = updateOrder;
		model.resetOrder = resetOrder;
		model.orderItemUpdateInProcess = false;
		model.isShippingFulfillment = true;
		model.setEditMode = setEditMode;
		model.showEdit = false;
		model.setActiveCardGroup = setActiveCardGroup;
		model.setActiveCardAmount = setActiveCardAmount;
		model.canViewRestrictedCardNumbers = authService.canViewRestrictedCardNumbers();
		model.canActivateWithoutSwipe = authService.hasClaim(CLAIMS.ACTIVATE_WITHOUT_SWIPE);
		model.maskPrintText = cardService.maskPrintText;
		model.resetGroupLookup = resetGroupLookup;
		model.ORDER_STATUS = {
			"CREATED": "Created",
			"FULFILLED": "Fulfilled",
			"IN_PROGRESS": "InProgress",
			"FUNDED": "Funded",
			"CANCELLED": "Cancelled",
		};
		model.KYB_STATUS = {
			"NOT_APPLICABLE": "NotApplicable",
			"PENDING": "Pending",
			"APPROVED": "Approved",
			"DECLINED": "Declined"
		}
		model.PRE_ACTIVATION_TYPE = LOOKUP_TYPES;
		model.setPreActivationType = setPreActivationType;
		model.isPreActivationType = isPreActivationType;
		model.setActiveCardGroup = setActiveCardGroup;
		model.removeCardsConfirmModalPopup = removeCardsConfirmModalPopup;
		model.canNavigateNext = canNavigateNext;
		model.canNavigatePrevious = canNavigatePrevious;
		model.setNextActiveCardGroup = setNextActiveCardGroup;
		model.setPreviousActiveCardGroup = setPreviousActiveCardGroup;
		model.confirmHvtVerification = confirmHvtVerification;
		model.cancelHvtVerification = cancelHvtVerification;
		model.showOrderInfo = showOrderInfo;
		model.hvtThreshold = hvt_threshold;
		model.shippingMethods = [];
		model.groupActivationLoading = false;
		$scope.pageSizeOptions = [5, 10, 25, 50];
		$scope.pageSize = 10;
		$scope.historyPageSize = 5;
		$scope.historySortKey = 'dateFulfilled';
		$scope.historySortAscending = false;
		$scope.historyCurrentPage = 1;
		$scope.defaultOrder = {};
        $scope.forms = {};
        model.cardFrontOrderId = null;
		model.isCancelled = isCancelled;
		model.getCancelConfirmationMessage = getCancelConfirmationMessage;
		model.isDTC = false;
		model.getOrderItemCardAmountCardholdersExport = getOrderItemCardAmountCardholdersExport;
		model.orderType;
		model.FULFILLMENT_ORDER_TYPES = FULFILLMENT_ORDER_TYPES;
		model.truncate = truncate;
		model.isActivationLockDisabled = isActivationLockDisabled;
		model.isElectronicShippingMethod = isElectronicShippingMethod;
		model.getKybStatusDisplay = getKybStatusDisplay;

		$scope.dateFormat = $scope.currentDateFormat();

		model.pagination = {
			pageSize: $scope.pageSizeOptions[0]
		};

		var contactId = authService.currentContactId();

		var TAB_NAMES = {
			QUEUE: "queue",
			HISTORY: "history",
			DETAIL: "detail"
		};

		$scope.TAB_NAMES = TAB_NAMES;
		var activeTab = TAB_NAMES.QUEUE;

		var QUEUE_TYPES = {
			CONSUMER: "consumer",
			CORPORATE: "corporate"
		};
		$scope.QUEUE_TYPES = QUEUE_TYPES;
		model.queueType = QUEUE_TYPES.CONSUMER;
		var isCorporateQueue = model.queueType.toLowerCase() === QUEUE_TYPES.CORPORATE;

		init();

		function init() {
			model.queueType = $location.path().split('/').pop();
			getFulfillmentQueue();
		}

		$scope.$on(SF_EVENTS.CARDSWIPE_SUCCESS, handleCardSwipeSuccess);

		function resetFulfillment() {
			model.activationInProcess = false;
			model.orderItemUpdateInProcess = false;
			model.fulfillmentOrder = null;
			model.fulfillmentQueue = null;
			model.fulfillmentHistory = null;
			model.showEdit = false;
		}

		function resetLookup() {
			model.card.printText = null;
			$scope.forms.preActivateCardForm.$setPristine();
			$scope.forms.preActivateCardForm.cardNumber.$setPristine();
			notify.dismissErrorAlert();
		}

		function preActivateCardByPrintText() {
			notify.forFormSubmission($scope.forms.preActivateCardForm, doPreactivateCard(model.card.printText));
		}

		function preActivateGroupCardByFirstPrintText() {
			notify.forFormSubmission($scope.forms.preActivateGroupCardForm, doPreactivateCard(model.card.firstPrintText));
		}

		function preActivateGroupCardByLastPrintText() {
			model.groupActivationLoading = true;
			notify.forFormSubmission($scope.forms.preActivateGroupCardForm, doPreactivateCard(model.card.lastPrintText));
		}

		function handleCardSwipeSuccess(event, swipe) {
			if (!model.currentCardGroup.isPreactivated)
				doPreactivateCard(null, swipe.primaryAccountNumber);
		}

		function doPreactivateCard(printText, primaryAccountNumber) {
			notify.dismissErrorAlert();

			// ensure not already added
			if (isDuplicateCard(printText, primaryAccountNumber)) {
				notify.showError("FULFILLMENT.VALIDATION_TEXT.CARD_ALREADY_ADDED");
				model.groupActivationLoading = false;
				return;
			};

			var preActivateCardPromise;
			if (printText) {
				preActivateCardPromise = fulfillmentService.preActivateByPrintText(model.fulfillmentOrder.orderId, printText);
			} else if (primaryAccountNumber) {
				preActivateCardPromise = fulfillmentService.preActivateBySwipe(model.fulfillmentOrder.orderId, primaryAccountNumber);
			} else {
				notify.showError("LOOKUP_CARD.FORM.INVALID_PRINT_TEXT_OR_SWIPE");
				model.groupActivationLoading = false;
				return;
			}

			notify.forHttp(preActivateCardPromise, { startTranslationId: "FULFILLMENT.FORM.PREACTIVATION_PROGRESS_TEXT" })
				.then(data => handlePreActivationResponse(data),() => {model.groupActivationLoading = false});
		}

		function doPreActivateGroupCards(firstCardNumber, lastCardNumber) {
			notify.dismissErrorAlert();

			notify.forHttp(fulfillmentService.preActivateGroup(model.fulfillmentOrder.orderId, firstCardNumber, lastCardNumber), { startTranslationId: "LOOKUP_CARD.FORM.PROGRESS_TEXT" })
				.then(data => {
					handlePreActivationGroupResponse(data);
				})
				.catch(() => resetGroupLookup());
		}

		function resetGroupLookup() {
			model.card.firstPrintText = null;
			model.card.lastPrintText = null;
			model.card.firstPrimaryAccountNumber = null;
			model.card.lastPrimaryAccountNumber = null;
			model.card.groupCardCount = null;
			model.firstGroupCard = null;
			model.lastGroupCard = null;
			model.firstCardPreactivated = false;
			model.lastCardPreactivated = false;
			model.groupActivationLoading = false;
			$scope.forms.preActivateGroupCardForm.$setPristine();
			notify.dismissErrorAlert();
		}

		function isDuplicateCard(printText, primaryAccountNumber) {
			var isDupe = true;

			if (primaryAccountNumber) {
				isDupe = model.fulfillmentOrder.orderItems.findIndex(o => o.flattenedAmounts.find(a => a.primaryAccountNumber === primaryAccountNumber.toString())) > -1;
			}
			if (printText) {
				isDupe = model.fulfillmentOrder.orderItems.findIndex(o => o.flattenedAmounts.find(a => a.printText === printText.toString())) > -1;
			}
			return isDupe;
		}

		function handlePreActivationResponse(data) {

			let orderItem = getActiveOrderItem();
			let cardGroup = getActiveCardGroup();

			if (isPreActivationType(model.PRE_ACTIVATION_TYPE.INDIVIDUAL)) {
				let card = model.orderType !== model.FULFILLMENT_ORDER_TYPES.REGISTERED_PHYSICAL 
				? cardGroup.flattenedAmounts.find(x => x.amount === cardGroup.amount && !x.printText)
				: orderItem.flattenedAmounts[orderItem.flattenedAmounts.findIndex(
					x => x.amount === model.currentCardGroup.amount && !x.printText)];
				card.printText = data.printText;
				card.primaryAccountNumber = data.primaryAccountNumber;
				card.cardId = data.cardId;
				cardGroup.preactivatedQuantity = model.orderType !== model.FULFILLMENT_ORDER_TYPES.REGISTERED_PHYSICAL
				? cardGroup.flattenedAmounts.reduce((qty, cardAmount) => {
					if (cardAmount.printText) {
						qty += 1;
					}
					return qty;
				}, 0)
				: orderItem.flattenedAmounts.reduce((qty, cardAmount) => {
					if (cardAmount.printText) {
						qty += 1;
					}
					return qty;
				}, 0);

				// update state on active card group
				cardGroup.isPreactivated = 
				model.orderType !== model.FULFILLMENT_ORDER_TYPES.REGISTERED_PHYSICAL
					? cardGroup.flattenedAmounts.findIndex(x => !x.printText) === -1
					: data.printText === cardGroup.printText;
				
				cardGroup.isActive = !cardGroup.isPreactivated;
				cardGroup.cardNumberDisplay = getGroupCardNumberDisplay(cardGroup, orderItem);


				if (!cardGroup.isActive) {
					if (model.orderType !== model.FULFILLMENT_ORDER_TYPES.REGISTERED_PHYSICAL)
						setActiveCardGroup(orderItem, cardGroup.index + 1);
					else
						setActiveCardAmount(orderItem, orderItem.flattenedAmounts[orderItem.flattenedAmounts.findIndex(x => !x.printText)]);
				}
				resetLookup();

			} else {
				if (!model.firstCardPreactivated) {
					model.card.firstPrintText = Number(data.printText);
					model.card.firstPrimaryAccountNumber = data.primaryAccountNumber;
					model.firstCardPreactivated = true;
				} else if (!model.lastCardPreactivated) {
					model.card.lastPrintText = Number(data.printText);
					model.card.lastPrimaryAccountNumber = data.primaryAccountNumber;
					model.lastCardPreactivated = true;
					// now have the first and last preactivated,
					// so now need to do the preActivateGroup
					doPreActivateGroupCards(model.card.firstPrimaryAccountNumber, model.card.lastPrimaryAccountNumber);
				}
			}
		}

		function handlePreActivationGroupResponse(data) {
			let orderItem = getActiveOrderItem();
			let cardGroup = getActiveCardGroup();

			let cards = cardGroup.flattenedAmounts.filter(x => x.amount === cardGroup.amount && !x.printText);

			if (cards.length < data.cards.length) {
				notify.showError("FULFILLMENT.FORM.INVALID_GROUP_PREACTIVATION");
				resetGroupLookup();
				return;
			}

			data.cards.forEach(preActivatedCard => {
				let card = cardGroup.flattenedAmounts.find(x => x.amount === cardGroup.amount && !x.printText);
				if (card) {
					card.printText = preActivatedCard.printText;
					card.primaryAccountNumber = preActivatedCard.primaryAccountNumber;
					card.cardId = preActivatedCard.cardId;
				}
			});

			cardGroup.preactivatedQuantity = cardGroup.flattenedAmounts.reduce((qty, cardAmount) => {
				if (cardAmount.printText) {
					qty += 1;
				}
				return qty;
			}, 0);

			cardGroup.isPreactivated = cardGroup.flattenedAmounts.findIndex(x => !x.printText) === -1;
			cardGroup.isActive = !cardGroup.isPreactivated;
			cardGroup.cardNumberDisplay = cardGroup.isPreactivated ? getGroupCardNumberDisplay(cardGroup, orderItem) : null;

			if (!cardGroup.isActive) {
				setActiveCardGroup(orderItem, cardGroup.index + 1);
			}

			resetGroupLookup();
		}

		function getGroupCardNumberDisplay(cardGroup, orderItem) {
			if (!cardGroup.isPreactivated) return null;

			if (cardGroup.quantity > 1) {
				cardGroup.flattenedAmounts.sort(function (a, b) {
					if (a.printText < b.printText) {
						return -1;
					}
					if (a.printText > b.printText) {
						return 1;
					}
					// must be equal
					return 0;
				});
				let firstCard = cardGroup.flattenedAmounts[0];
				let lastCard = cardGroup.flattenedAmounts[cardGroup.flattenedAmounts.length - 1];

				return getPrintTextDisplay(firstCard.printText) + ' - ' + getPrintTextDisplay(lastCard.printText);

			} else {
				let card = model.orderType !== model.FULFILLMENT_ORDER_TYPES.REGISTERED_PHYSICAL
				? cardGroup.flattenedAmounts[0]
				: orderItem.flattenedAmounts.length > 1
				? orderItem.flattenedAmounts[model.currentCardGroup.index]
				: orderItem.flattenedAmounts[0];
				return getPrintTextDisplay(card.printText);
			}
		}

		function getPrintTextDisplay(printText) {
			if (!printText) return null;
			return model.canViewRestrictedCardNumbers ? printText : cardService.maskPrintText(printText);
		}

		function getActiveOrderItem() {
			if (model.orderType !== model.FULFILLMENT_ORDER_TYPES.REGISTERED_PHYSICAL)
				return model.fulfillmentOrder.orderItems.find(o => o.cardGroups.find(c => c.isActive));
			else
				return model.fulfillmentOrder.orderItems.find(o => o.flattenedAmounts.find(c => c.isActive));
		}

		function getActiveCardGroup() {
			let orderItem = getActiveOrderItem();
			if (orderItem) {
				if (model.orderType !== model.FULFILLMENT_ORDER_TYPES.REGISTERED_PHYSICAL)
					return orderItem.cardGroups.find(a => a.isActive);
				else
					return orderItem.flattenedAmounts.find(a => a.isActive);
			}
		}

		function doLookupFulfillmentOrder(orderId) {
			notify.dismissErrorAlert();
			resetFulfillment();
			activeTab = TAB_NAMES.DETAIL;

			if (!orderId) {
				notify.showError("FULFILLMENT.FORM.INVALID_ORDER_ID");
				return;
			}

            notify.forHttp(fulfillmentService.getFulfillmentOrder(orderId, model.queueType === QUEUE_TYPES.CORPORATE), { startTranslationId: "FULFILLMENT.FORM.PROGRESS_TEXT" })
				.then(data => setFulfillmentOrder(data));
		}

		function setFulfillmentOrder(data) {
			model.activationInProcess = false;
            model.fulfillmentOrder = data;
            model.cardFrontOrderId = data.orderId;
			model.fulfillmentOrder.isFulfilled = model.fulfillmentOrder.orderStatus === fulfilled;
			model.shippingMethodNames = data.shippingMethodNames;
			if (data.orderType)
				model.orderType = data.orderType;
			model.lockTransactionOnActivation = model.orderType === FULFILLMENT_ORDER_TYPES.REGISTERED_PHYSICAL ? true : false;
			model.fulfillmentOrder.orderItems.forEach(mapToCards);
			model.fulfillmentOrder.orderItems.forEach(x => {
				x.cardGroups = mapOrderCardGroups(x);
				x.shippingMethodName = getShippingMethodName(x);
			});
			if (model.orderType !== model.FULFILLMENT_ORDER_TYPES.REGISTERED_PHYSICAL)
				setActiveCardGroup(model.fulfillmentOrder.orderItems[0], 0);
			else
				setActiveCardAmount(model.fulfillmentOrder.orderItems[0], model.fulfillmentOrder.orderItems[0].flattenedAmounts[0]);
			$scope.defaultOrder = angular.copy(model.fulfillmentOrder);
            model.canEditTrackingInformation = isTrackingInformationEditable();
			checkForHvtApprovalRequirement();
			sortCardholder("default");
		}

		function mapToCards(orderDetail) {
			model.isShippingFulfillment = orderDetail.fulfillmentType === shipping;
			orderDetail.receiptHtmlPromise = orderDetail.activationTransactionId 
				? () => receipts.getReceipt(orderDetail.activationTransactionId, $translate.currentLanguage().key, false, model.cardFrontOrderId)
					.then(
						receipt => receipt.receiptHtml
					) 
				: null;

			let flattenedAmounts = [];
			var amountIndex = 0;
			orderDetail.amounts.forEach(amountGroup => {
				for(var i = 0; i < amountGroup.quantity; i++) {
					flattenedAmounts.push({
						cardId: amountGroup.cardId,
						amount: amountGroup.amount,
						orderItemCardAmountId: amountGroup.orderItemCardAmountId,
						primaryAccountNumber: null,
						externalId: amountGroup.externalId,
						isPreactivated: amountGroup.printText,
						printTextDisplay: getPrintTextDisplay(amountGroup.printText),
						cardholder: amountGroup.cardholders[i],
					 	index: amountIndex,
						orderItemCardAmountCardholderId: amountGroup?.cardholders[i]?.orderItemCardAmountCardholderId});
					amountIndex++;
				}
				orderDetail.flattenedAmounts = flattenedAmounts;
				model.isDTC = amountGroup.cardholders[0]?.address1 ? true : false;
			})
		}

		function mapOrderCardGroups(orderDetail) {
			return orderDetail.amounts.reduce((cardGroups, item) => {
				var cardGroup = cardGroups.find(x => x.amount === item.amount);
				if (!cardGroup) {
					let amounts = orderDetail.amounts.filter(x => x.amount === item.amount);
					cardGroups.push({
						amount: item.amount,
						quantity: item.quantity,
						orderItemId: orderDetail.orderItemId,
						amounts: amounts,
						flattenedAmounts: orderDetail.flattenedAmounts.filter(y => y.amount === item.amount),
						isActive: false,
						isPreactivated: false,
						cardNumberDisplay: null,
						preactivatedQuantity: 0
					});
				} else {
					cardGroup.quantity += item.quantity;
				}
				return cardGroups;
			}, []);
		}

		$scope.$on(EVENT_NAMES.ON_CULTURE_CHANGED, function () {
			if (model.fulfillmentQueue){
				model.fulfillmentQueue.forEach(queueItem => {
					queueItem.shippingMethodName = getShippingMethodName(queueItem);
				});
			}
			if (model.fulfillmentOrder) {
				model.fulfillmentOrder.orderItems.forEach(orderItem => {
					orderItem.shippingMethodName = getShippingMethodName(orderItem);
				});
			}
		})

		function formatSearchDate(dateToFormat) {
			return dateToFormat ? moment(dateToFormat).format("YYYY-MM-DD") : null;
		}

		function getFulfillmentQueue() {
			activeTab = TAB_NAMES.QUEUE;
			let orderBeginDate = formatSearchDate(model.queueSearch.orderBeginDate);
			let orderEndDate = formatSearchDate(model.queueSearch.orderEndDate);
			notify.forHttp(fulfillmentService.getFulfillmentQueue(
				model.queueSearch.filterText,
				orderBeginDate,
				orderEndDate,
				model.queueType === QUEUE_TYPES.CORPORATE
			), { startTranslationId: "FULFILLMENT.GET_FULFILLMENT_QUEUE_PROGRESS" })
				.then(data => setFulfillmentQueue(data));
		}

		function setFulfillmentQueue(data) {
			resetFulfillment();
			notify.forHttp(fulfillmentService.getShippingMethods(), { startTranslationId: "FULFILLMENT.GET_FULFILLMENT_QUEUE_PROGRESS" })
				.then(shippingMethodsList => {
					model.shippingMethods = shippingMethodsList;
					model.fulfillmentQueue = data.outstandingOrders.map(order => angular.extend(order,
					{ isFulfillable: getFulfillableStatus(order),
					shippingMethodName: getShippingMethodName(order) }))});
		}

		function getFulfillableStatus(order) {
			if (model.queueType === QUEUE_TYPES.CORPORATE){
				if (order.requireKybEnabled) {					
					return order.kybApproved && (order.orderStatus === model.ORDER_STATUS.FUNDED || order.orderStatus === model.ORDER_STATUS.IN_PROGRESS);					
				} 				
				return order.orderStatus === model.ORDER_STATUS.FUNDED || order.orderStatus === model.ORDER_STATUS.IN_PROGRESS;				
			} else {
				return order.orderStatus !== model.ORDER_STATUS.FULFILLED;
			}			
		}

		function getShippingMethodName(order) {
			if (!order.shippingMethodId) return null;

			let orderShippingMethods = model.shippingMethods.filter(sm => sm.shippingMethodId === order.shippingMethodId);

			if (orderShippingMethods.length === 1) {
				return orderShippingMethods[0].name;
			} else {
				let currentCulture = $translate.currentLanguage().key.toLowerCase();
				let cultureSegments = currentCulture.split('-');
				let match = orderShippingMethods.find(x => x.culture.toLowerCase() === currentCulture);
				if (!match){
					match = orderShippingMethods.find(x => x.culture.toLowerCase().split('-')[0] === cultureSegments[0]);
				}
				if (!match){
					match = orderShippingMethods.find(x => x.culture.toLowerCase().split('-')[1] === cultureSegments[1]);
				}
				if (!match) {
					let nameList = orderShippingMethods.map(x => x.name);
					return nameList.join(', ');
				} else {
					return match.name;
				}
			}
		}

		function historyPageChanged(newPageNumber) {
			if (newPageNumber) {
				$scope.historyCurrentPage = newPageNumber;
				getFulfillmentHistory();
			}
		}

		function historySortChanged(sortKey) {
			if (sortKey) {
				$scope.historySortKey = sortKey;
				$scope.historySortAscending = !$scope.historySortAscending;
				getFulfillmentHistory();
			}
		}

		function historyPageSizeChanged() {
			if (model.pagination.pageSize !== $scope.historyPageSize) {
				$scope.historyPageSize = model.pagination.pageSize;
				getFulfillmentHistory();
			}
		}

		function getFulfillmentHistory() {
			activeTab = TAB_NAMES.HISTORY;
			let orderBeginDate = formatSearchDate(model.historySearch.orderBeginDate);
			let orderEndDate = formatSearchDate(model.historySearch.orderEndDate);

			notify.forHttp(fulfillmentService.getFulfillmentHistory(
				$scope.historyCurrentPage,
				$scope.historyPageSize,
				model.historySearch.filterText,
				orderBeginDate,
				orderEndDate,
				$scope.historySortKey,
                $scope.historySortAscending,
                model.queueType === QUEUE_TYPES.CORPORATE), { startTranslationId: "FULFILLMENT.GET_FULFILLMENT_HISTORY_PROGRESS" })
				.then(data => setFulfillmentHistory(data));
		}

		function setFulfillmentHistory(data) {
			resetFulfillment();
			model.fulfillmentHistory = mapFulfillmentByDisplay(data.items);
			model.fulfillmentHistory.filteredItems = data.filteredItems;
			model.fulfillmentHistory.totalItems = data.totalItems;
		}

		function mapFulfillmentByDisplay(items) {
			return items.map(x => ({...x, fulfilledByDisplay: x.fulfilledBy ? x.fulfilledBy : x.orderStatus === fulfilled ? batchActivation : ""}));
		}

		function sort(columnKey) {
			model.sortKey = columnKey;
			model.reverse = !model.reverse;
		}

		function sortCardholder(columnKey) {
			model.cardholderSortKey = columnKey;
			switch (columnKey) {
				case "amount":
					model.fulfillmentOrder.orderItems[0].flattenedAmounts = 
						model.fulfillmentOrder.orderItems[0].flattenedAmounts.sort((a,b) => !model.cardholderReverse 
							? a.amount - b.amount 
							: b.amount - a.amount);
					break;
				case "cardholder":
					model.fulfillmentOrder.orderItems[0].flattenedAmounts =
						model.fulfillmentOrder.orderItems[0].flattenedAmounts.sort(compareLastNameAndFirstName)
					break;
				case "department":
					model.fulfillmentOrder.orderItems[0].flattenedAmounts = 
						model.fulfillmentOrder.orderItems[0].flattenedAmounts.sort(compareDepartments);
					break;
				case "location":
					model.fulfillmentOrder.orderItems[0].flattenedAmounts = 
						model.fulfillmentOrder.orderItems[0].flattenedAmounts.sort(compareLocations);
					break;
				default:
					model.fulfillmentOrder.orderItems[0].flattenedAmounts =
						model.fulfillmentOrder.orderItems[0].flattenedAmounts.sort((a,b) => !model.cardholderReverse
							? a.orderItemCardAmountCardholderId - b.orderItemCardAmountCardholderId
							: b.orderItemCardAmountCardholderId - a.orderItemCardAmountCardholderId);
					break;
			}
			var amount = model.fulfillmentOrder.orderItems[0].flattenedAmounts;
			for(var i=0; i < amount.length; i++)
			{
				amount[i].index = i;
			}
			if (model.orderType === FULFILLMENT_ORDER_TYPES.REGISTERED_PHYSICAL)
				setActiveCardAmount(model.fulfillmentOrder.orderItems[0], model.fulfillmentOrder.orderItems[0].flattenedAmounts[0]);
		}

		function compareLastNameAndFirstName(a,b) {
			const lastNameA = a.cardholder.lastName.toLowerCase();
			const lastNameB = b.cardholder.lastName.toLowerCase();
			if (model.cardholderReverse)
			{
				if (lastNameA < lastNameB) {
					return -1;
				} else if (lastNameA > lastNameB) {
					return 1;
				} else {
					// Last names are equal, so sort by first name
					const firstNameA = a.cardholder.firstName.toLowerCase();
					const firstNameB = b.cardholder.firstName.toLowerCase();
			
					if (firstNameA < firstNameB) {
						return -1;
					} else if (firstNameA > firstNameB) {
						return 1;
					} else {
						return 0;
					}
				}
			} else {
				if (lastNameA > lastNameB) {
					return -1;
				} else if (lastNameA < lastNameB) {
					return 1;
				} else {
					// Last names are equal, so sort by first name
					const firstNameA = a.cardholder.firstName.toLowerCase();
					const firstNameB = b.cardholder.firstName.toLowerCase();
			
					if (firstNameA > firstNameB) {
						return -1;
					} else if (firstNameA < firstNameB) {
						return 1;
					} else {
						return 0;
					}
				}
			}
		}

		function compareDepartments(a, b) {
			if (model.cardholderReverse) {
				if (a.cardholder.department < b.cardholder.department)
					return -1;
				if (a.cardholder.department > b.cardholder.department)
					return 1;
				return 0;
			} else {
				if (a.cardholder.department > b.cardholder.department)
					return -1;
				if (a.cardholder.department < b.cardholder.department)
					return 1;
				return 0;
			}
		}

		function compareLocations(a, b) {
			if (model.cardholderReverse) {
				if (a.cardholder.location < b.cardholder.location)
					return -1;
				if (a.cardholder.location > b.cardholder.location)
					return 1;
				return 0;
			} else {
				if (a.cardholder.location > b.cardholder.location)
					return -1;
				if (a.cardholder.location < b.cardholder.location)
					return 1;
				return 0;
			}
		}

		function getOrderStatusDisplay(status) {
			if (status === model.ORDER_STATUS.FULFILLED) {
				return "FULFILLMENT.ORDER_PROPERTIES.ORDER_STATUS.FULFILLED";
			} else if (status === model.ORDER_STATUS.IN_PROGRESS) {
				return "FULFILLMENT.ORDER_PROPERTIES.ORDER_STATUS.IN_PROGRESS";
			} else if (status === model.ORDER_STATUS.FUNDED) {
				return "FULFILLMENT.ORDER_PROPERTIES.ORDER_STATUS.FUNDED";
			} else {
				return "FULFILLMENT.ORDER_PROPERTIES.ORDER_STATUS.CREATED";
			}
		}

		function getKybStatusDisplay(order) {
			if (!order.requireKybEnabled) {
				return "FULFILLMENT.ORDER_PROPERTIES.KYB_STATUS.NOT_APPLICABLE"
			} else if (order.kybApproved === undefined || order.kybApproved === null) {
				return "FULFILLMENT.ORDER_PROPERTIES.KYB_STATUS.PENDING"
			} else if (!order.kybApproved) {
				return "FULFILLMENT.ORDER_PROPERTIES.KYB_STATUS.DECLINED"
			} else if (order.kybApproved) {
				return "FULFILLMENT.ORDER_PROPERTIES.KYB_STATUS.APPROVED"
			}
		}

		function isInProgress(status) {
			return status === model.ORDER_STATUS.IN_PROGRESS;
		}

		function isCancelled(status) {
			return status === model.ORDER_STATUS.CANCELLED;
		}

		function getStatusModifiedDate(order) {
			if (order.statusModifiedDate === "0001-01-01T00:00:00" || !isInProgress(order.orderStatus)) {
				return "";
			}
			return order.statusModifiedDate;
		}

		function beginFulFillment(orderId, modifyWhileInProgress = false) {
			fulfillmentService.beginFulfillment(orderId, modifyWhileInProgress)
				.then(response => processStatusChangeRequest(response, orderId));
		}

		function processStatusChangeRequest(response, orderId) {
			if (!response.data.succeeded && response.data.statusContactId != contactId) {
				model.currentOrderId = orderId;
				model.dialogMessage = response.data.message;
				checkForOverride();
			} else {
				doLookupFulfillmentOrder(orderId);
			}
		}

		function checkForOverride() {
			ngDialog.open({
				templateUrl: 'override-dialog-template.html',
				className: "ngdialog-theme-default",
				data: model
			});
		}

		function overRideFulfillmentAssociation() {
			ngDialog.close();
			beginFulFillment(model.currentOrderId, true);
		}

		function cancelOverride() {
			ngDialog.close();
		}

		function setActiveCard(orderItem, index) {
			if (model.fulfillmentOrder && model.fulfillmentOrder.orderStatus !== fulfilled) {
				orderItem.cardGroups[index].isActive = true;
				model.currentCardGroup = orderItem.cardGroups[index];
			}
		}

		function setActiveCardGroup(orderItem, index) {

			if (!model.fulfillmentOrder || model.fulfillmentOrder.orderStatus === fulfilled) return;

			orderItem.cardGroups.map(x => x.isActive = false);

			// set next cardGroup to active
			let next = orderItem.cardGroups[index];
			if (!next) {
				//see if any other cardGroups need preactivation
				next = orderItem.cardGroups.find(x => x.isPreactivated === false);
			}

			// everything is preactivated
			if (!next) return;

			next.isActive = true;
			model.currentCardGroup = next;
			currentPreActivationType = model.currentCardGroup.quantity === 1 ?
				model.PRE_ACTIVATION_TYPE.INDIVIDUAL :
				model.PRE_ACTIVATION_TYPE.GROUP;
		}

		function setActiveCardAmount(orderItem, selectedItem) {
			if (!model.fulfillmentOrder || model.fulfillmentOrder.orderStatus === fulfilled) return;

			orderItem.flattenedAmounts.map(x => x.isActive = false);

			// set next flattened amount to active
			let next = orderItem.flattenedAmounts[orderItem.flattenedAmounts.findIndex(a => a.cardholder?.orderItemCardAmountCardholderId === selectedItem?.cardholder?.orderItemCardAmountCardholderId)];
			if (!next) {
				//see if any other cards need preactivation
				next = orderItem.flattenedAmounts.find(x => x.isPreactivated === false);
			}

			//everything is preactivated
			if (!next) return;

			next.isActive = true;
			model.currentCardGroup = next;
			
			currentPreActivationType = model.PRE_ACTIVATION_TYPE.INDIVIDUAL;
		}

		function setNextActiveCardGroup(orderItem) {
			if (model.fulfillmentOrder.orderType !== model.FULFILLMENT_ORDER_TYPES.REGISTERED_PHYSICAL)
				return setActiveCardGroup(orderItem, model.currentCardGroup.index + 1);
			else
				return setActiveCardAmount(orderItem, orderItem.flattenedAmounts[model.currentCardGroup.index + 1]);
		}

		function setPreviousActiveCardGroup(orderItem) {
			if (model.fulfillmentOrder.orderType !== model.FULFILLMENT_ORDER_TYPES.REGISTERED_PHYSICAL)
				return setActiveCardGroup(orderItem, model.currentCardGroup.index - 1);
			else
				return setActiveCardAmount(orderItem, orderItem.flattenedAmounts[model.currentCardGroup.index - 1]);
		}

		function isValidForActivation() {
			if (model.fulfillmentOrder) {
				switch (model.orderType) {
					case FULFILLMENT_ORDER_TYPES.DIGITAL:
						return true;
					case FULFILLMENT_ORDER_TYPES.VIRTUAL:
						return true;
					case FULFILLMENT_ORDER_TYPES.REGISTERED_PHYSICAL:
						return model.fulfillmentOrder.orderItems.findIndex(o => o.flattenedAmounts?.find(a => !a.isPreactivated)) === -1;
					default:
						// all amounts in all order items must be preactivated
						return model.fulfillmentOrder.orderItems.findIndex(o => o.cardGroups?.find(a => !a.isPreactivated)) === -1;
				}
			}
		}

		function activateOrder() {
			model.activationInProcess = true;
			notify.forHttp(fulfillmentService.fulfillOrder(
				model.fulfillmentOrder.orderId,
				mapfulfillmentOrder(),
				model.lockTransactionOnActivation,
				model.orderType),
				{ startTranslationId: "FULFILLMENT.FULFILL_ORDER_PROGRESS" })
			.then(() => doLookupFulfillmentOrder(model.fulfillmentOrder.orderId),
				  () => doLookupFulfillmentOrder(model.fulfillmentOrder.orderId));
		}

		function updateOrder(orderItem) {
			model.orderItemUpdateInProcess = true;
			notify.forHttp(fulfillmentService.updateTrackingInformation(
				model.fulfillmentOrder.orderId,
				orderItem.orderItemId,
				orderItem.trackingInformation,
				{ startTranslationId: "FULFILLMENT.UPDATE_ORDER_PROGRESS" }))
				.finally(() => doLookupFulfillmentOrder(model.fulfillmentOrder.orderId));
		}

		function cancelOrder(orderId) {
			fulfillmentService.cancelOrder(orderId)
				.then(() => getFulfillmentQueue());
		}

		function mapfulfillmentOrder() {
			if (model.orderType === FULFILLMENT_ORDER_TYPES.DIGITAL)
				return model.fulfillmentOrder.orderItems
				.map(oi => { return { orderItemId: oi.orderItemId, orderItemCardAmounts: mapDigitalInfo(oi) }; });


			return model.fulfillmentOrder
				.orderItems
				.map(oi => { return { orderItemId: oi.orderItemId, trackingInformation: oi.trackingInformation, cards: mapCardInfo(oi) }; });
		}

		function mapCardInfo(orderItem) {
			return orderItem.flattenedAmounts.map(a => { return { cardId: a.cardId, orderItemCardAmountId: a.orderItemCardAmountId, primaryAccountNumber: a.primaryAccountNumber, orderItemCardAmountCardholderId: a.cardholder?.orderItemCardAmountCardholderId }; });
		}

		function mapDigitalInfo(orderItem) {
			return orderItem.amounts.map(a => {return { orderItemCardAmountId: a.orderItemCardAmountId, amount: a.amount, quantity:a.quantity }})
		}

		function isTrackingInformationEditable() {
			return model.fulfillmentOrder && model.fulfillmentOrder.isFulfilled ? model.showEdit : true;
		}

		function changeTrackingInformation() {
			model.trackingInformationChanged = true;
		}

		function getShippingAddressForPrint(orderItem) {

			let space = " ",
				newLine = "<br/>",
				address2 = orderItem.address2 ? orderItem.address2 + newLine : "";

			let shippingAddress = orderItem.firstName.concat(
				space,
				orderItem.lastName,
				newLine,
				orderItem.address1,
				newLine,
				address2,
				orderItem.city,
				", ",
				orderItem.stateOrProvince,
				space,
				orderItem.zipOrPostalCode);

			return "<html><body><div>" + shippingAddress + "</div></body></html>";
		}

		function cancelActivation() {
			fulfillmentService.cancelFulfillment(model.fulfillmentOrder.orderId)
				.then(() => getFulfillmentQueue());
		}

		$scope.tabClass = function (tabName) {
			var cssClass = "";
			if (tabName === activeTab) {
				cssClass = "active";
			}
			return cssClass;
		}

		$scope.tabVisible = function (tabName) {
			return tabName === activeTab;
		};

		$scope.$watch(function () {
			return window.hidSwipeData ? window.hidSwipeData.primaryAccountNumber : null;
		}, handleHidSwipe);

		function handleHidSwipe() {
			if (window.hidSwipeData && window.hidSwipeData.primaryAccountNumber) {
				doPreactivateCard(undefined, window.hidSwipeData.primaryAccountNumber);
				window.hidSwipeData.primaryAccountNumber = null;
			}
		}

		$window.handleHidSwipe = function handleHidSwipe(primaryAccountNumber) {
			if (primaryAccountNumber) {
				doPreactivateCard(undefined, primaryAccountNumber);
				primaryAccountNumber = null;
			}
		}

		function setEditMode() {
			model.showEdit = true;
			model.canEditTrackingInformation = isTrackingInformationEditable();
		}

		function resetOrder() {
			angular.copy($scope.defaultOrder, model.fulfillmentOrder);
			model.showEdit = false;
			model.canEditTrackingInformation = isTrackingInformationEditable();
		}

		var currentPreActivationType = model.PRE_ACTIVATION_TYPE.GROUP;
		function setPreActivationType(type) {
			currentPreActivationType = type;
		}

		function isPreActivationType(type) {
			return type === currentPreActivationType;
		}

		function removeCardsConfirmModalPopup(cardGroup) {
			var removeCardsConfirmModalInstance = $uibModal.open({
				templateUrl: "fulfillment/modal/removeCardsConfirmDialog.html",
				backdrop: "static",
				keyboard: false,
				size: "sm",
				controller: "ConfirmModal",
				scope: $scope
			})

			removeCardsConfirmModalInstance.result.then(function (status) {
				clearCards(cardGroup, status);
			});
		}

		function clearCards(cardGroup, confirmed) {
			if (confirmed) {
				resetCardGroup(cardGroup);
				if (model.fulfillmentOrder.orderType !== FULFILLMENT_ORDER_TYPES.REGISTERED_PHYSICAL)
					notify.success("FULFILLMENT.CONFIRMATION.ALL_CARDS_REMOVED", undefined, 1000);
				else
					notify.success("FULFILLMENT.CONFIRMATION.CARD_REMOVED", undefined, 1000);
			}
		}

		function resetCardGroup(cardGroup) {
			cardGroup.isPreactivated = false;
			cardGroup.preactivatedQuantity = 0;
			cardGroup.amounts?.forEach(x => {
				x.cardId = null;
				x.printText = null;
				x.primaryAccountNumber = null;
			});
			cardGroup.flattenedAmounts?.forEach(x => {
				x.cardId = null;
				x.printText = null;
				x.primaryAccountNumber = null;
			})
			cardGroup.cardId = null;
			cardGroup.printText = null;
			cardGroup.primaryAccountNumber = null;
			if (model.orderType === FULFILLMENT_ORDER_TYPES.REGISTERED_PHYSICAL)
				setActiveCardAmount(model.fulfillmentOrder.orderItems[0], model.fulfillmentOrder.orderItems[0].flattenedAmounts[cardGroup.index]);
		}

		function canNavigateNext(orderItem) {
			if (!model.currentCardGroup) return false;
			if (model.orderType !== model.FULFILLMENT_ORDER_TYPES.REGISTERED_PHYSICAL)
				return model.currentCardGroup.index + 1 > orderItem.cardGroups.length - 1;
			else
			return model.currentCardGroup.index +1 > orderItem.flattenedAmounts.length -1;
		}

		function canNavigatePrevious() {
			if (!model.currentCardGroup) return false;
			return model.currentCardGroup.index - 1 < 0;
		}

		function checkForHvtApprovalRequirement() {
			if (model.fulfillmentOrder.orderTotal < hvt_threshold ||
				model.fulfillmentOrder.isFulfilled ||
				!model.fulfillmentOrder.purchaserInformation) return;

			ngDialog.open({
				templateUrl: 'hvt-dialog-template.html',
				className: "ngdialog-theme-default",
				data: model,
				width: '50%',
				closeByDocument: false,
				closeByNavigation: true,
				closeByEscape: false,
				showClose: false
			});
		}

		function showOrderInfo(orderId)
		{
            notify.forHttp(fulfillmentService.getFulfillmentOrder(orderId, model.queueType === QUEUE_TYPES.CORPORATE), { startTranslationId: "FULFILLMENT.FORM.PROGRESS_TEXT" })
				.then(
					data => {
						setFulfillmentOrder(data);
						ngDialog.open({
							templateUrl: 'order-info-dialog-template.html',
							className: "ngdialog-theme-default",
							data: model,
							width: '50%',
							closeByDocument: false,
							closeByNavigation: true,
							closeByEscape: true,
							showClose: true
						});
					});
			return false;
		}

		function confirmHvtVerification(){
			ngDialog.close();
		}

		function cancelHvtVerification() {
			ngDialog.close();
			model.getFulfillmentQueue();
		}

		function getCancelConfirmationMessage() {
			return $translate.instant("FULFILLMENT.CANCEL_ORDER_CONFIRMATION_TEXT");
		}

		function getOrderItemCardAmountCardholdersExport(orderId) {
			notify.dismissErrorAlert();

			notify.forHttp(fulfillmentService.getOrderItemCardAmountCardholdersExport(orderId), { startTranslationId: "FULFILLMENT.GET_CARDHOLDERS_EXPORT_PROGRESS" })
			.then(data => {
				var blob = new Blob([data], {
                    type: "text/csv"
                });
                FileSaver.saveAs(blob, getCardholdersFileName());
			})
			.catch((error) => { notify.showError(error); });
		}

		function getCardholdersFileName() {
			return $translate.instant("FULFILLMENT.FORM.CARDHOLDERS_FILE_NAME", {orderId: model.fulfillmentOrder.customerOrderReference}) || ("cardholders-order-" + model.fulfillmentOrder.customerOrderReference + ".csv");
		}

		function truncate(str, maxLength) {
			var newString = (str?.length > maxLength) ? str?.slice(0, maxLength-1) + '...' : str;
			return newString;
		}

		function isActivationLockDisabled() {
			var disabled = false;

			if (model.orderType === model.FULFILLMENT_ORDER_TYPES.REGISTERED_PHYSICAL)
				disabled = true;

			return disabled;
		}

		function isElectronicShippingMethod(orderItem) {
			if (orderItem.shippingMethodName)
				return orderItem.shippingMethodName;

			if (model.orderType === model.FULFILLMENT_ORDER_TYPES.DIGITAL)
				return $translate.instant("FULFILLMENT.ELECTRONIC_SHIPPING_METHOD_LABEL");

			return null;
		}
	}
}());