(function () {
    "use strict"

    angular.module("angular.emlpayments")
        .factory("orderService", orderServiceFactory);

    orderServiceFactory.$inject = ["api", "appConfig", "notify"];

    function orderServiceFactory(api, appConfig, notify) { 
        var searchOrderCriteria = null;

        return {
            getCardFrontOrders,
            getSearchOrderCriteria,
            getCardFrontOrderCardDetails,
            searchCardFrontOrderCardDetails,
            getCardFrontOrderDetails,
            saveCardFrontOrderDetails,
            saveCardFrontCardDetails,
            deleteCardFrontCardholder,
            insertCardFrontCardholder,
            getPostalCodeRegexByCountry,
            reset
        };

        function getCardFrontOrders(searchOrderModel, orderStartDate, orderEndDate, statusChangeStartDate, statusChangeEndDate) {
            searchOrderCriteria = searchOrderModel;

            return api.order.getCardFrontOrders(appConfig.selectedMerchantGroup().uniqueTag,
                searchOrderModel.pageNumber,
                searchOrderModel.pageSize,
                orderStartDate,
                orderEndDate,
                statusChangeStartDate,
                statusChangeEndDate,
                searchOrderModel.orderId,
                searchOrderModel.orderStatus,
                searchOrderModel.qualifier,
                searchOrderModel.orderAmount,
                searchOrderModel.organizationName,
                searchOrderModel.purchaseOrderNumber,
                searchOrderModel.orderPlacedBy,
                searchOrderModel.sortAscending,
                searchOrderModel.sortColumn);
        }

        function getSearchOrderCriteria() {
            return searchOrderCriteria;
        }

        function getCardFrontOrderCardDetails(
        pageNumber,
        pageSize,
        customerOrderReference) {
            return api.order.getCardFrontOrderCardDetails(pageNumber, pageSize, customerOrderReference);
        }

        function searchCardFrontOrderCardDetails(
        pageNumber,
        pageSize,
        customerOrderReference,
        searchText) {
            return api.order.searchCardFrontOrderCardDetails(pageNumber, pageSize, customerOrderReference, searchText);
        }

        function getCardFrontOrderDetails(
        customerOrderReference) {
            return api.order.getCardFrontOrderDetails(customerOrderReference);
        }

        function saveCardFrontOrderDetails(orderDetails) {
            return api.order.saveCardFrontOrderDetails(orderDetails);
        }

        function saveCardFrontCardDetails(cardDetails) {
            return api.order.saveCardFrontCardDetails(cardDetails);
        }

        function deleteCardFrontCardholder(orderItemId, orderItemCardAmountCardholderId, cardAmount) {
            return api.order.deleteCardFrontCardholder(orderItemId, orderItemCardAmountCardholderId, cardAmount);
        }

        function insertCardFrontCardholder(orderItemId, cardAmount, cardholder) {
            return api.order.insertCardFrontCardholder(orderItemId, cardAmount, cardholder);
        }

        function getPostalCodeRegexByCountry(country) {
            switch(country) {
                case "United States":
                    return new RegExp("^\\d{5}(?:[-\\s]\\d{4})?$");
                case "Ireland":
                    return new RegExp("[\\dA-Z]{3} ?[\\dA-Z]{4}","i");
            }
        }

        function reset() {
            searchOrderCriteria = null;
        }
    }
}());