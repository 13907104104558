﻿(function () {
    angular.module("cardspotWeb.areas.global")
        .controller("LookupOrdersConfirmModal", LookupOrdersConfirmModal);

    LookupOrdersConfirmModal.$inject = ["$scope", "$uibModalInstance"];

    function LookupOrdersConfirmModal($scope, $uibModalInstance) {
        $scope.yes = yes;
        $scope.no = no;

        function yes(status) {
            $uibModalInstance.close(true);
        }

        function no(status) {
            $uibModalInstance.close(false);
        }
    }
}());