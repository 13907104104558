import htmlTemplate from './lookup-orders.html';

(function () {
    "use strict";

    angular.module("cardspotWeb")
        .config(configLookupOrders);

    configLookupOrders.$inject = ["$routeProvider", "NAV_URLS", "CLAIMS"];

    function configLookupOrders($routeProvider, NAV_URLS, CLAIMS) {

        $routeProvider
            .when(NAV_URLS.LOOKUP_ORDERS + "/:id?", {
                template: htmlTemplate,
                controller: "LookupOrders",
                controllerAs: "model",
                requiredClaim: CLAIMS.LOOKUP_ORDERS
            });
    }
}());