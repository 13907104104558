﻿(function () {
    angular.module("cardspotWeb.areas.global")
        .controller("ConfirmModal", ConfirmModal);

    ConfirmModal.$inject = ["$scope", "$uibModalInstance"];

    function ConfirmModal($scope, $uibModalInstance) {
        $scope.yes = yes;
        $scope.no = no;
        $scope.data = $scope.$resolve.params?.data;

        function yes(status) {
            $uibModalInstance.close(true);
        }

        function no(status) {
            $uibModalInstance.close(false);
        }
    }
}());