import { zip } from "underscore";

(function () {
	"use strict";
	angular.module("cardspotWeb.areas.lookupOrders")
    .controller("OrderModal", OrderModal);

	OrderModal.$inject = ["$scope", "$uibModalInstance", "lookupValues", "REGION_TYPES", "FULFILLMENT_ORDER_TYPES", "orderService"];

	function OrderModal($scope, $uibModalInstance, lookupValues, REGION_TYPES, FULFILLMENT_ORDER_TYPES, orderService) {
        $scope.REGION_TYPES = REGION_TYPES;
        $scope.ORDER_TYPES = FULFILLMENT_ORDER_TYPES;
        
        $scope.order = {
            orderId: $scope.$resolve.params?.order.orderId,
            siteId: $scope.$resolve.params?.order.siteId,
            siteVersionId: $scope.$resolve.params?.order.siteVersionId,
            programId: $scope.$resolve.params?.order.programId,
            merchantGroupId: $scope.$resolve.params?.order.merchantGroupId,
            customerOrderReference: $scope.$resolve.params?.order.customerOrderReference,
            orderType: $scope.$resolve.params?.order.orderType,
            feeTotal: $scope.$resolve.params?.order.feeTotal,
            perCardFee: $scope.$resolve.params?.order.perCardFee,
            numberOfCards: $scope.$resolve.params?.order.numberOfCards,
            transactionFeeAmount: $scope.$resolve.params?.order.transactionFeeAmount,
            shippingFee: $scope.$resolve.params?.order.shippingFee,
            orderAmount: $scope.$resolve.params?.order.orderAmount,
            orderStatus: $scope.$resolve.params?.order.orderStatus,
            purchaseOrderNumber: $scope.$resolve.params?.order.purchaseOrderNumber,
            billingInformation: {
                id: $scope.$resolve.params?.order.billingInformation.id,
                firstName: $scope.$resolve.params?.order.billingInformation.firstName,
                lastName: $scope.$resolve.params?.order.billingInformation.lastName,
                address1: $scope.$resolve.params?.order.billingInformation?.address1,
                address2: $scope.$resolve.params?.order.billingInformation?.address2,
                city: $scope.$resolve.params?.order.billingInformation?.city,
                region: {
                    regionKey: $scope.$resolve.params?.order.billingInformation?.regionKey,
                    regionName: $scope.$resolve.params?.order.billingInformation?.stateOrProvince
                },
                stateOrProvince: $scope.$resolve.params?.order.billingInformation?.stateOrProvince,
                zipOrPostalCode: $scope.$resolve.params?.order.billingInformation?.zipOrPostalCode,
                country: {
                    countryKey:$scope.$resolve.params?.order.billingInformation?.country,
                    countryName: $scope.$resolve.params?.order.billingInformation?.country,
                    postalCodeRegexValidationPattern: postalCodeRegexByCountry($scope.$resolve.params?.order.billingInformation?.country),
                    regionLabel: $scope.$resolve.params?.order.billingInformation?.regionType,
                    regionType: $scope.$resolve.params?.order.billingInformation?.regionType
                }
            },
            shippingInformation: {
                id: $scope.$resolve.params?.order.shippingInformation?.id,
                firstName: $scope.$resolve.params?.order.shippingInformation?.firstName,
                lastName: $scope.$resolve.params?.order.shippingInformation?.lastName,
                address1: $scope.$resolve.params?.order.shippingInformation?.address1,
                address2: $scope.$resolve.params?.order.shippingInformation?.address2,
                city: $scope.$resolve.params?.order.shippingInformation?.city,
                region: {
                    regionKey: $scope.$resolve.params?.order.shippingInformation?.regionKey,
                    regionName: $scope.$resolve.params?.order.shippingInformation?.stateOrProvince
                },
                stateOrProvince: $scope.$resolve.params?.order.shippingInformation?.stateOrProvince,
                zipOrPostalCode: $scope.$resolve.params?.order.shippingInformation?.zipOrPostalCode,
                country: {
                    countryKey:$scope.$resolve.params?.order.shippingInformation?.country,
                    countryName: $scope.$resolve.params?.order.shippingInformation?.country,
                    postalCodeRegexValidationPattern: postalCodeRegexByCountry($scope.$resolve.params?.order.shippingInformation?.country),
                    regionLabel: $scope.$resolve.params?.order.shippingInformation?.regionType,
                    regionType: $scope.$resolve.params?.order.shippingInformation?.regionType
                }
            },
            currency: {
                alphaCode: $scope.$resolve.params?.order?.currencyInformation?.alphaCode,
                amlActivationThresholdAmount: $scope.$resolve.params?.order?.currencyInformation?.amlActivationThresholdAmount,
                currencySymbol: $scope.$resolve.params?.order?.currencyInformation?.currencySymbol,
                defaultCultureName: $scope.$resolve.params?.order?.currencyInformation?.defaultCultureName,
                description: $scope.$resolve.params?.order?.currencyInformation?.description,
                numericCode: $scope.$resolve.params?.order?.currencyInformation?.numericCode
            }
        }

        init();

        function init() {
            lookupValues.getCountries()
            .then(function (data) {
                $scope.countries = data;
            });

            initBillingRegions();
            initShippingRegions();
        }

        function initBillingRegions() {
            if ($scope.order?.billingInformation?.country) {
                var countryKey = $scope.order.billingInformation.country.countryKey;
                if (countryKey) {
                    lookupValues.getRegions(countryKey)
                    .then(function (regions) {
                        $scope.billingRegions = regions;
                        $scope.showBillingRegionSelection = $scope.order.billingInformation.country && hasBillingRegions() && $scope.order.billingInformation.country.regionType !== REGION_TYPES.NONE;
                        $scope.order.billingInformation.country.postalCodeRegexValidationPattern = postalCodeRegexByCountry($scope.order.billingInformation.country.countryName);
                    });
                }
            }
        }

        function initShippingRegions() {
            if ($scope.order?.shippingInformation?.country) {
                var countryKey = $scope.order.shippingInformation.country.countryKey;
                if (countryKey) {
                    lookupValues.getRegions(countryKey)
                    .then(function (regions) {
                        $scope.shippingRegions = regions;
                        $scope.showShippingRegionSelection = $scope.order.shippingInformation.country && hasShippingRegions() && $scope.order.shippingInformation.country.regionType !== REGION_TYPES.NONE;
                        $scope.order.shippingInformation.country.postalCodeRegexValidationPattern = postalCodeRegexByCountry($scope.order.shippingInformation.country.countryName);
                    });
                }
            }
        }

        function hasBillingRegions() {
            return angular.isArray($scope.billingRegions) && $scope.billingRegions.length > 0;
        }

        function hasShippingRegions() {
            return angular.isArray($scope.shippingRegions) && $scope.shippingRegions.length > 0;
        }

        function postalCodeRegexByCountry(country) {
            orderService.getPostalCodeRegexByCountry(country);
        }

		$scope.saveOrder = function () {
			$uibModalInstance.close($scope.order);
		};

		$scope.cancelSaveOrder = function () {
			$uibModalInstance.dismiss("cancel");
		};

        $scope.billingCountrySelectionChanged = function () {
            initBillingRegions();
        }

        $scope.shippingCountrySelectionChanged = function () {
            initShippingRegions();
        }

        function reset() {
            $scope.orderUpdateForm.$setPristine();
        }
	}
}());