(function () {
    "use strict";
    angular.module("cardspotWeb.areas.lookupOrders")
        .run(['$anchorScroll', function($anchorScroll) {
            $anchorScroll.yOffset = 80;   // always scroll by 80 extra pixels
        }])
        .controller("LookupOrders", LookupOrders);

    LookupOrders.$inject = ["$scope", "ORDER_STATUS", "orderService", "notify", "$rootScope", "$anchorScroll", "$timeout", 
        "fulfillmentService", "FULFILLMENT_ORDER_TYPES", "$uibModal", "$translate", "authService", "moment"];

    function LookupOrders($scope, ORDER_STATUS, orderService, notify, $rootScope, $anchorScroll, $timeout, fulfillmentService,
        FULFILLMENT_ORDER_TYPES, $uibModal, $translate, authService, moment) {
        var searchOrderCriteria = null;
        var model = this;

        model.search = {
            pageNumber: 1,
            pageSize: 5,
            orderStartDate: null,
            orderEndDate: null,
            statusChangeStartDate: null,
            statusChangeEndDate: null,
            orderId: null,
            orderStatus: null,
            qualifier: null,
            orderAmount: null,
            organizationName: null,
            purchaseOrderNumber: null,
            orderPlacedBy: null,
            sortAscending: true,
            sortColumn: "OrderId"
        }

        model.orders = null;
        model.cardholderDetails = null;
        model.reset = reset;
        model.isValidSearchCriteria = isValidSearchCriteria;
        model.searchOrders = searchOrders;
        model.selectedCurrencySymbol = "¤";
        model.ORDER_STATUS = ORDER_STATUS;
        model.cardFrontOrdersPageChanged = cardFrontOrdersPageChanged;
        model.goToOrderDetails = goToOrderDetails;
        model.chPageNumber = 1;
        model.chPageSize = 10;
        model.cardholderDetailsPageChanged = cardholderDetailsPageChanged;
        model.isDateReadOnly = isDateReadOnly;
        model.shippingMethods = [];
        model.getShippingMethodName = getShippingMethodName;
        model.ORDER_TYPES = FULFILLMENT_ORDER_TYPES;
        model.cardholderSearch = null;
        model.searchCardFrontOrderCardDetails = searchCardFrontOrderCardDetails;
        model.getOrderCardholderDetails = getOrderCardholderDetails;
        model.editingRowData = {};
        model.modifyCardholder = modifyCardholder;
        model.cancelCardholderUpdate = cancelCardholderUpdate;
        model.addCardholder = addCardholder;
        model.modifyOrder = modifyOrder;
        model.isEditable = isEditable;
        model.removeCardholder = removeCardsConfirmModalPopup;
        model.canAddCardholder = authService.canAddOrderCardholder();
        model.canDeleteCardholder = authService.canDeleteOrderCardholder();
        model.canEditOrders = authService.canEditOrders();
        model.canEditCardholder = authService.canEditOrderCardholder();
        model.sort = sort;
        model.isDtc = false;
        
        $scope.datePopups = {
            orderStartDatePopup: {
                opened: false
            },
            orderEndDatePopup: {
                opened: false
            },
            statusChangeStartDatePopup: {
                opened: false
            },
            statusChangeEndDatePopup: {
                opened: false
            }
        };

        $scope.openOrderStartDatePicker = function () {
            $scope.datePopups.orderStartDatePopup.opened = true;
        };
        $scope.openOrderEndDatePicker = function () {
            $scope.datePopups.orderEndDatePopup.opened = true;
        };
        $scope.statusChangeStartDatePicker = function () {
            $scope.datePopups.statusChangeStartDatePopup.opened = true;
        };
        $scope.statusChangeEndDatePicker = function () {
            $scope.datePopups.statusChangeEndDatePopup.opened = true;
        };

        init();

        function init() {
            searchOrderCriteria = orderService.getSearchOrderCriteria();
            if (searchOrderCriteria) {
                model.search = searchOrderCriteria; 
                doSearchOrders(model.search);
            }

            getShippingMethods();
        }

        function getShippingMethods() {
            notify.dismissErrorAlert();
			notify.forHttp(fulfillmentService.getShippingMethods(), { startTranslationId: "LOOKUP_ORDERS.SHIPPING_METHODS_PROGRESS_TEXT" })
				.then(shippingMethodsList => {
					model.shippingMethods = shippingMethodsList;
            });
		}
        
        function isValidSearchCriteria() {
            var isValid = (model.search.orderStartDate || model.search.orderEndDate || model.search.statusChangeStartDate
                || model.search.statusChangeEndDate || model.search.orderId || model.search.orderStatus 
                || (model.search.qualifier && model.search.orderAmount) || model.search.organizationName || model.search.purchaseOrderNumber
                || model.search.orderPlacedBy);
            return isValid;
        }

        function searchOrders() {
            model.orders = null;
            model.currentOrder = null;
            
            notify.forFormSubmission($scope.searchOrderForm, doSearchOrders(model.search));
        }

        function formatSearchDate(dateToFormat) {
			return dateToFormat ? moment(dateToFormat).format("MM-DD-YYYY") : null;
		}

        function doSearchOrders(searchOrderModel) {
            let orderStartDate = formatSearchDate(model.search.orderStartDate);
            let orderEndDate = formatSearchDate(model.search.orderEndDate);
            let statusChangeStartDate = formatSearchDate(model.search.statusChangeStartDate);
            let statusChangeEndDate = formatSearchDate(model.search.statusChangeEndDate);
            
            notify.dismissErrorAlert();
            if (!isValidSearchCriteria()) {
                notify.showError("LOOKUP_ORDERS.FORM.INVALID_SEARCH_CRITERIA");
                return;
            }

            notify.forHttp(orderService.getCardFrontOrders(searchOrderModel, orderStartDate, orderEndDate, statusChangeStartDate, statusChangeEndDate), { startTranslationId: "LOOKUP_ORDERS.FORM.PROGRESS_TEXT"})
                .then(function (response) {
                    setSearchResults(response);
                })
        }

        function setSearchResults(response) {
            if (!response.items || response.items.length === 0) {
                notify.showError("LOOKUP_ORDERS.FORM.NO_RESULTS_FOUND");
            } else {
                model.orders = response.items;
                model.orders.totalItems = response.totalItems;
                model.orders.forEach(o => {
                    if (o.orderDate) {
                        o.orderDate = new Date(o.orderDate);
                    }
                    if (o.statusChangeDate) {
                        o.statusChangeDate = new Date(o.statusChangeDate)
                    }
                });
                $timeout(function () {
                    $anchorScroll("orderResults");
                });
            }
        }

        function cardFrontOrdersPageChanged(newPageNumber) {
            if (newPageNumber) {
                model.search.pageNumber = newPageNumber;
                doSearchOrders(model.search);
            }
        }

        function refreshOrdersGrid() {
            doSearchOrders(model.search);
        }

        function goToOrderDetails(e, customerOrderReference) {
            e.preventDefault();
            model.currentOrder = model.orders.filter(o => o.customerOrderReference === customerOrderReference);

            getOrderCardholderDetails(model.chPageNumber, model.chPageSize, customerOrderReference);
        }

        function getOrderCardholderDetails(pageNumber, pageSize, customerOrderReference) {
            notify.dismissErrorAlert();
            model.cardholderSearch = null;

            notify.forHttp(
                orderService.getCardFrontOrderCardDetails(pageNumber, pageSize, customerOrderReference),
            { startTranslationId: "LOOKUP_ORDERS.ORDER_DETAILS.PROGRESS_TEXT" })
            .then(function (response) {
                setCardholderDetails(response);
            })
        }

        function searchCardFrontOrderCardDetails() {
            notify.dismissErrorAlert();

            notify.forHttp(
                orderService.searchCardFrontOrderCardDetails(model.chPageNumber, model.chPageSize, model.currentOrder[0].customerOrderReference, model.cardholderSearch),
            { startTranslationId: "LOOKUP_ORDERS.ORDER_DETAILS.PROGRESS_TEXT" })
            .then(function (response) {
                setCardholderDetails(response);
            })
        }

        function setCardholderDetails(response) {
            if (!response.items || response.items.length === 0) {
                notify.showError("LOOKUP_ORDERS.FORM.NO_RESULTS_FOUND");
            } else { 
                model.cardholderDetails = response.items;
                model.cardholderDetails.totalItems = response.totalItems;
                model.currentOrder[0].isBulk = response.items[0].isBulk;
                
                $timeout(function () {
                    $anchorScroll("orderDetails");
                });
            }
        }

        function cardholderDetailsPageChanged(newPageNumber) {
            if (newPageNumber) {
                model.chPageNumber = newPageNumber;
                if (model.cardholderSearch)
                    searchCardFrontOrderCardDetails()
                else
                    getOrderCardholderDetails(model.chPageNumber, model.chPageSize, model.currentOrder[0].customerOrderReference);
            }
        }

        function isDateReadOnly(date) {
            if (date) {
                var paramDate = new Date(date).toLocaleDateString();
                var currentDate = new Date().toLocaleDateString();
                
                return paramDate === currentDate;
            }
            
            return false;
        }

        function getShippingMethodName(order) {
			if (!order.shippingMethodId) return null;

			let orderShippingMethods = model.shippingMethods.filter(sm => sm.shippingMethodId === order.shippingMethodId);

			if (orderShippingMethods.length === 1) {
				return orderShippingMethods[0].name;
			} else {
				let currentCulture = $translate.currentLanguage().key.toLowerCase();
				let cultureSegments = currentCulture.split('-');
				let match = orderShippingMethods.find(x => x.culture.toLowerCase() === currentCulture);
				if (!match){
					match = orderShippingMethods.find(x => x.culture.toLowerCase().split('-')[0] === cultureSegments[0]);
				}
				if (!match){
					match = orderShippingMethods.find(x => x.culture.toLowerCase().split('-')[1] === cultureSegments[1]);
				}
				if (!match) {
					let nameList = orderShippingMethods.map(x => x.name);
					return nameList.join(', ');
				} else {
					return match.name;
				}
			}
		}

        function modifyOrder(order) {
            orderService.getCardFrontOrderDetails(order.customerOrderReference)
            .then((response) => {
                model.orderDetails = response.data;
                orderUpdateModalPopup(model.orderDetails);
            })
        }

        function removeCardsConfirmModalPopup(rowData) {
			var removeCardholderConfirmModalInstance = $uibModal.open({
				templateUrl: "lookup-orders/removeCardholderConfirmDialog.html",
				backdrop: "static",
				keyboard: false,
				size: "sm",
				controller: "LookupOrdersConfirmModal",
				scope: $scope
			})

			removeCardholderConfirmModalInstance.result
            .then(function (status) {
                if (status === true)
				    removeCardholder(rowData);
			});
		}

        function removeCardholder(rowData) {
            orderService.deleteCardFrontCardholder(
                rowData.orderItemId, 
                rowData.orderItemCardAmountCardholderId, 
                rowData.cardAmount)
            .then((response) => {
                if (response.data.succeeded)
                {
                    notify.success("Cardholder deleted successfully!");
                    refreshOrdersGrid();
                    refreshCardholderGrid();
                }
            })
        }

        function modifyCardholder(rowData) {
            cardholderUpdateModalPopup(rowData);
        }

        function cancelCardholderUpdate(rowData) {
		    notify.dismissErrorAlert();
		    refreshCardholderGrid();
            model.editingRowData[rowData.orderItemCardAmountCardholderId] = false;
		}

        function refreshCardholderGrid() {
            getOrderCardholderDetails(model.chPageNumber, model.chPageSize, model.currentOrder[0].customerOrderReference);
        }

        function orderUpdateModalPopup(order) {
            let orderUpdateModalInstance = $uibModal.open({
                templateUrl: "lookup-orders/order-modal.html",
                backdrop: "static",
                keyboard: false,
                controller: "OrderModal",
                scope: $scope,
                resolve: {
                    params: function () {
                        return {
                            order: order
                        }
                    }
                }
            });

            orderUpdateModalInstance.result
            .then(function (result) {
                notify.forHttp(orderService.saveCardFrontOrderDetails(result), { startTranslationId: "LOOKUP_ORDERS.ORDER_RESULTS.SAVE_PROGRESS_TEXT"})
                .then((response) => {
                    if (response < 0) {
                        refreshOrdersGrid();
                        notify.success("LOOKUP_ORDERS.ORDER_RESULTS.SAVE_SUCCESS_TEXT");
                    }
                }, function (error) {
                    if (error) notify.showError(error);
                })
            }, function (error) {
                console.log(error);
            })
        }

        function cardholderUpdateModalPopup(rowData) {
            let cardholderUpdateModalInstance = $uibModal.open({
                templateUrl: "lookup-orders/cardholder-modal.html",
                backdrop: "static",
                keyboard: false,
                controller: "CardholderModal",
                scope: $scope,
                resolve: {
                    params: function () {
                        return {
                            detail: rowData
                        }
                    }
                }
            });

            cardholderUpdateModalInstance.result
            .then(function (result) {
                notify.forHttp(orderService.saveCardFrontCardDetails(result), { startTranslationId: "LOOKUP_ORDERS.ORDER_RESULTS.CARDHOLDER_SAVE_PROGRESS_TEXT"})
                .then((response) => {
                    if (response.succeeded) {
                        refreshOrdersGrid();
                        refreshCardholderGrid();
                        notify.success("LOOKUP_ORDERS.ORDER_RESULTS.CARDHOLDER_SAVE_SUCCESS_TEXT");
                    }
                }, function (error) {
                    if (error) notify.showError(error);
                })
            }, function (error) {
                if (error) {
                    console.log(error);
                }
            });
        }

        function addCardholder() {
            let cardholderAddModalInstance = $uibModal.open({
                templateUrl: "lookup-orders/cardholder-modal.html",
                backdrop: "static",
                keyboard: false,
                controller: "CardholderModal",
                scope: $scope,
                resolve: {
                    params: function () {
                        return {
                            detail: model.currentOrder[0]
                        }
                    }
                }
            });

            cardholderAddModalInstance.result
            .then(function (result) {
                var stuff = model.currentOrder[0];
                notify.forHttp(orderService.insertCardFrontCardholder(result.orderItemId, result.cardAmount, result), { startTranslationId: ""})
                .then((response) => {
                    if (response.succeeded) {
                        refreshOrdersGrid();
                        refreshCardholderGrid();
                        notify.success("LOOKUP_ORDERS.ORDER_RESULTS.CARDHOLDER_SAVE_SUCCESS_TEXT");
                    }
                })
            }, function (error) {
                if (error) {
                    console.log(error);
                }
            })
        }

        function isEditable(order) {
            if (order)
                return order.orderStatus === model.ORDER_STATUS.CREATED || order.orderStatus === model.ORDER_STATUS.KYB_APPROVED;
        }

        function sort(sortColumn) {
            model.search.sortColumn = sortColumn;
            model.search.sortAscending = !model.search.sortAscending;
            doSearchOrders(model.search);
        }

        function resetSearchForm() {
            model.search.pageNumber = 1,
            model.search.pageSize = 5,
            model.search.orderStartDate = null;
            model.search.orderEndDate = null;
            model.search.statusChangeStartDate = null;
            model.search.statusChangeEndDate = null;
            model.search.orderId = null;
            model.search.orderStatus = null;
            model.search.qualifier = null;
            model.search.orderAmount = null;
            model.search.organizationName = null;
            model.search.purchaseOrderNumber = null;
            model.search.orderPlacedBy = null;
            model.search.sortAscending = true;
            model.search.sortColumn = "OrderId";
            $scope.searchOrderForm.$setPristine();
        }

        function reset() {
            resetSearchForm();
            model.orders = null;
            model.currentOrder = null;
            model.cardholderDetails = null;
            model.chPageNumber = 1;
            model.chPageSize = 10;
            notify.dismissErrorAlert();
            orderService.reset();
        }
    }
}());
