﻿(function () {
    "use strict";

    angular.module("cardspotWeb")
        .config(configModule);

    configModule.$inject = ["$provide"];

    function configModule($provide) {

        //using a decorator for authService here because the signage confirmation feature is cardspot web specific and
        //the hope is the authService can be made a reusable component
        $provide.decorator("authService", decorateAuthService);

        decorateAuthService.$inject = ["$delegate", "$window", "CLAIMS"];

        function decorateAuthService($delegate, $window, CLAIMS) {

            var signageConfirmedKey = "signageConfirmed",
                isReauthenticatingKey = "isReauthenticatingKey",
                passwordChangeRequiredKey = "passwordChangeRequiredKey";

            $delegate.signageConfirmed = function () {
                return $window.sessionStorage.getItem(signageConfirmedKey) === "true";
            };
            $delegate.confirmSignage = function () {
                $window.sessionStorage.setItem(signageConfirmedKey, "true");
            };
            $delegate.removeConfirmSignage = function () {
                $window.sessionStorage.removeItem(signageConfirmedKey);
            };
            $delegate.isReauthenticating = function () {
                return $window.sessionStorage.getItem(isReauthenticatingKey) === "true";
            };
            $delegate.setReauthenticationKey = function () {
                $window.sessionStorage.setItem(isReauthenticatingKey, "true");
            };
            $delegate.removeReauthenticationKey = function () {
                $window.sessionStorage.removeItem(isReauthenticatingKey);
            };
            $delegate.isPasswordChangeRequired = function () {
                return $window.sessionStorage.getItem(passwordChangeRequiredKey) === "true";
            };
            $delegate.setPasswordChangeRequiredKey = function () {
                $window.sessionStorage.setItem(passwordChangeRequiredKey, "true");
            };
            $delegate.removePasswordChangeRequiredKey = function () {
                $window.sessionStorage.removeItem(passwordChangeRequiredKey);
            };
            $delegate.canModifyTransferFee = function () {
                return $delegate.hasClaim(CLAIMS.MODIFY_TRANSFER_FEE);
            };
            $delegate.canDeleteFee = function () {
                return $delegate.hasClaim(CLAIMS.DELETE_FEE);
            };
            $delegate.canAddCustomerInformation = function () {
                return $delegate.hasClaim(CLAIMS.ADD_CUSTOMER_INFORMATION);
            };
            $delegate.canLookupCard = function () {
                return $delegate.hasClaim(CLAIMS.LOOKUP_CARD);
            };
            $delegate.canAddCampaigns = function () {
                return $delegate.hasClaim(CLAIMS.ADD_CAMPAIGNS);
            };
            $delegate.canLockCard = function () {
                return $delegate.hasClaim(CLAIMS.LOCK_CARD);
            };

            $delegate.canUnlockCard = function () {
                return $delegate.hasClaim(CLAIMS.UNLOCK_CARD);
            };

            $delegate.canTransferCard = function () {
                    return $delegate.hasClaim(CLAIMS.TRANSFER_CARD);
            };

            $delegate.canViewActivationDetails = function () {
                return $delegate.hasClaim(CLAIMS.LOOKUP_ACTIVATION_TRANSACTION);
            };

            $delegate.canActivateCard = function () {
                return $delegate.hasClaim(CLAIMS.ACTIVATE_CARD);
            };

            $delegate.canVoidTransaction = function () {
                return $delegate.hasClaim(CLAIMS.VOID_CARD);
            };

            $delegate.canSearchCustomer = function () {
                return $delegate.hasClaim(CLAIMS.SEARCH_CUSTOMER);
            };
            $delegate.canAddSoftwareClientInstance = function () {
                return $delegate.hasClaim(CLAIMS.ADD_SOFTWARE_CLIENT_INSTANCE);
            };

            $delegate.canManageReceiptItems = function () {
                return $delegate.hasClaim(CLAIMS.MANAGE_RECEIPT_ITEMS);
            };

            $delegate.canManagePropertySettings = function () {
              return $delegate.hasClaim(CLAIMS.MANAGE_SETTINGS);
            };

            $delegate.canManageReceiptTermsAndConditions = function () {
                return $delegate.hasClaim(CLAIMS.MANAGE_RECEIPT_TERMS_AND_CONDITIONS);
            };

            $delegate.canViewCardFrontOrders = function () {
                return $delegate.hasClaim(CLAIMS.VIEW_CARDFRONT_ORDERS);
            }

	        $delegate.canManageCampaigns = function() {
	            return $delegate.hasClaim(CLAIMS.MANAGE_CAMPAIGNS);
	        };

	        $delegate.canManageLocations = function () {
	            return $delegate.hasClaim(CLAIMS.ADD_SOFTWARE_CLIENT_INSTANCE) && $delegate.hasClaim(CLAIMS.MANAGE_LOCATIONS);
	        }

	        $delegate.canManageMerchantGroupSundries = function () {
	            return $delegate.hasClaim(CLAIMS.MANAGE_MERCHANT_GROUP_SUNDRIES);
	        }

	        $delegate.canViewRestrictedCardNumbers = function () {
	            return $delegate.hasClaim(CLAIMS.VIEW_RESTRICTED_CARD_NUMBERS);
            }

            $delegate.isReprintDisabled = function () {
                return $delegate.hasClaim(CLAIMS.DISABLE_REPRINT);
            }

	        $delegate.canReconcileCardInventory = function () {
	            return $delegate.hasClaim(CLAIMS.RECONCILE_CARD_INVENTORY);
	        }

            $delegate.canOrderCards = function () {
                return $delegate.hasClaim(CLAIMS.ORDER_CARDS);
            }           

            $delegate.canFulfillCorporate = function () {
                return $delegate.hasClaim(CLAIMS.FULFILL_CORPORATE_ORDERS);
            };

            $delegate.canFulfillConsumer = function () {
                return $delegate.hasClaim(CLAIMS.FULFILL_CARDFRONT_ORDERS);
            }

            $delegate.canManageCards = function () {
                return $delegate.hasClaim(CLAIMS.MANAGE_CARDS) || $delegate.hasClaim(CLAIMS.ORDER_CARDS)
            }

            $delegate.canExtendAccountExpiration = function () {
                return $delegate.hasClaim(CLAIMS.EXTEND_ACCOUNT_EXPIRATION);
            }

            $delegate.canLookupCardFrontOrders = function () {
                return $delegate.hasClaim(CLAIMS.LOOKUP_ORDERS);
            }
            $delegate.canEditOrders = function () {
                return $delegate.hasClaim(CLAIMS.EDIT_ORDERS);
            }
            $delegate.canEditOrderCardholder = function () {
                return $delegate.hasClaim(CLAIMS.EDIT_ORDER_CARDHOLDER)
            }
            $delegate.canDeleteOrderCardholder = function () {
                return $delegate.hasClaim(CLAIMS.DELETE_ORDER_CARDHOLDER);
            }
            $delegate.canAddOrderCardholder = function () {
                return $delegate.hasClaim(CLAIMS.ADD_ORDER_CARDHOLDER);
            }
            return $delegate;
        }

    }


}());
